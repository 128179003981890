<template>
  <div>
    <s-search
      :queryItems="queryItems"
      @fetchData="fetchUsers"
      ref="SSearch"
      v-if="SSearchShow"
    ></s-search>
    <div class="table-button table-button-flexonly">
      <div>
        <a-button
          type="plain"
          :disabled="selectedRowKeys.length < 1"
          @click="CancelDispatch()"
          >取消派单项</a-button
        >
      </div>
      <div>
        <a-button type="plain" icon="redo" @click="$refs.table.refresh(true)"
          >刷新</a-button
        >
        <columns-set
          tableName="UndispatchedOrder"
          :columns="columns"
          @changeColumns="changeColumns"
        ></columns-set>
      </div>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="did"
      :columns="tableColumns"
      :data="loadData"
      :showPagination="true"
      :scroll="{ x: '1100px', y: 'calc(82vh - 150px )' }"
      tableLayout="fixed"
      :rowSelection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
      <!--  @change="changeTable" -->
      <!-- <span slot="wsBcId" slot-scope="text">
        <div>{{ CompaniesResult(text) }}</div>
      </span> -->

      <span slot="cdPId" slot-scope="text, record">
        <div>{{ record.tproject ? record.tproject.pname : "" }}</div>
      </span>
      <span slot="tproject" slot-scope="text">
        {{ text ? (text.tworkerType ? text.tworkerType.wtType : "") : "" }}
      </span>

      <span slot="dexpectStartTime" slot-scope="text, record">
        <div v-if="text == ''">{{ "-" }}</div>
        <div v-else>
          {{ $Format(text).slice(0, 10) }} ~
          {{ $Format(record.dexpectEndTime).slice(0, 10) }}
        </div>
      </span>

      <!-- 操作 -->
      <span slot="action" slot-scope="text, record" class="action">
        <a
          class="editBtnonly"
          v-if="
            isShowBtns.indexOf(
              'UndispatchedOrder-/api/constructionDetail/update'
            ) > -1
          "
          @click="showDrawer('AssignWorkerShow', record, '指定工人')"
          >指定</a
        >
        <a
          class="editBtnonly"
          v-if="
            isShowBtns.indexOf(
              'UndispatchedOrder-/api/constructionDetail/update'
            ) > -1
          "
          @click="showDrawer('SendOrdersShow', record, '设置派单规则')"
          >派单</a
        >
        <a class="">
          <a-tooltip overlayClassName="overlayClassNameonly">
            <template slot="title">
              <div
                class="cursorPointeronly"
                @click="
                  showDrawer('UndispatchedorderViewShow', record, '派单项详情')
                "
              >
                详情
              </div>
              <div class="cursorPointeronly" @click="CancelDispatch(record)">
                取消派单项
              </div>
            </template>
            更多
          </a-tooltip>
        </a>
      </span>
    </s-table>
    <a-drawer
      :title="DrawerTitle"
      placement="right"
      :closable="true"
      :visible="visibleDrawer"
      :after-visible-change="afterVisibleChange"
      @close="onClose"
      :width="750"
      :destroyOnClose="true"
    >
      <AssignWorker
        @onClose="onClose"
        :record="record"
        ref="AssignWorker"
        v-if="AssignWorkerShow"
        @recordChangeFn="recordChangeFn"
      />

      <SendOrders
        @onClose="onClose"
        :record="record"
        :AssignWorkerValue="AssignWorkerValue"
        ref="SendOrders"
        v-if="SendOrdersShow"
      />
      <UndispatchedorderView
        @onClose="onClose"
        :record="record"
        ref="UndispatchedorderView"
        v-if="UndispatchedorderViewShow"
      />
      <!-- @tabledataChange="($event) => tabledataChange()" -->
    </a-drawer>
  </div>
</template>

<script>
import { STable, SSearch, ColumnsSet } from "@/components";
import { SelectOptions } from "@/config/SelectOptions.js";
import { getWorkSitelist } from "@/api/apiJF/schedule";
import {
  GetConstructionDetailList,
  GetDispatchWorkList,
  DeleteDispatchWork,
} from "@/api/apiJF/order";
import { GetCompanies } from "@/api/device";
import AssignWorker from "./AssignWorker.vue";
import SendOrders from "./SendOrders.vue";
import UndispatchedorderView from "./UndispatchedorderView.vue";
import moment from "moment";
// 搜索条件
const queryItems = [
  {
    label: "管家",
    value: "cdWsWIdJl",
    type: "input",
    defaultValue: "",
  },
  {
    label: "分公司",
    value: "dBcIds",
    type: "select",
    selectOption: "CompanyListUnAll",
    defaultValue: "",
  },
  {
    label: "工地名称",
    value: "cdWsName",
    type: "input",
    defaultValue: "",
  },
  {
    label: "施工周期",
    value: "cdExpectTime",
    type: "dateRange",
    range: ["dexpectStartTime", "dexpectEndTime"],
    keyType: "WorkerAttend",
    defaultValue: [],
  },
  {
    label: "施工项",
    // value: "cdPName",
    type: "input",
    defaultValue: "",
  },
  {
    label: "工人岗位",
    // value: "cdPName",
    type: "select",
    defaultValue: "",
    selectOption: "WorkType",
  },
  {
    label: "施工项类型",
    // value: "cdPName",
    type: "select",
    defaultValue: "",
    selectOption: "TprojectList",
  },
];
const columns = [
  {
    title: "工地",
    // width: "100px",
    dataIndex: "dwsName",
    ellipsis: true,
    // align: "center",
  },
  {
    title: "派单项",
    width: "150px",
    dataIndex: "dpname",
    // align: "center",
  },
  {
    title: "所含施工项",
    width: "100px",
    dataIndex: "dconstructionNumber",

    // align: "center",
  },
  {
    title: "所需时间(小时)",
    width: "120px",
    scopedSlots: { customRender: "dduration" },
    dataIndex: "dduration",
    align: "center",
  },
  {
    title: "施工周期",
    width: "200px",
    scopedSlots: { customRender: "dexpectStartTime" },
    dataIndex: "dexpectStartTime",
    align: "center",
  },
  {
    title: "工人岗位",
    width: "100px",
    // dataIndex: "tproject",
    // scopedSlots: { customRender: "tproject" },
    align: "center",
  },

  {
    title: "所需工人数",
    width: "100px",
    dataIndex: "dpeople",
    align: "center",
  },
  {
    title: "指定工人情况",
    width: "110px",
    dataIndex: "dReceiveName",
    align: "center",
  },
  {
    title: "操作",
    width: "110px",
    scopedSlots: { customRender: "action" },
    fixed: "right",
    dataIndex: "action",
    align: "center",
  },
];

export default {
  name: "UndispatchedOrder",
  components: {
    STable,
    SSearch,
    ColumnsSet,
    AssignWorker,
    SendOrders,
    UndispatchedorderView,
  },
  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    return {
      tableColumns: [],
      SSearchShow: true,
      DrawerTitle: "",
      visibleDrawer: false,
      // 查询参数
      queryParam: {
        dWsName: "",
        dPName: "",
        dBcIds: "",
        pageNo: 1,
        pageSize: 10,
      },
      isShowBtns: [],
      record: "",
      CompanyList: {},
      type: "",
      filters: {},
      CompanyListid: [],
      //   pageNo: 1,
      //   pageSize: 10,
      AssignWorkerShow: false,
      SendOrdersShow: false,
      UndispatchedorderViewShow: false,
      AssignWorkerValue: "",
      routeWsId: "",
      selectedRowKeys: [],
      selectedRows: [],
    };
  },

  created() {
    this.queryItems = JSON.parse(JSON.stringify(queryItems));
    this.tableColumns = columns;
    this.changeColumns();
    this.filters = SelectOptions;
    // console.log("this.$route.params", this.$route.params);
    let params = JSON.parse(JSON.stringify(this.$route.params));
    // console.log("params", params);
    this.routeWsId = params.id;
    // if (this.$route.params) {
    if (JSON.stringify(this.$route.params) != "{}") {
      if (params.dBcIds) {
        let params = {};
        params = JSON.parse(JSON.stringify(this.$route.params));
        // params = JSON.parse(this.$route.params);
        for (let item in params) {
          // 默认值改变
          let queryItems = JSON.parse(JSON.stringify(this.queryItems));
          queryItems.forEach((el) => {
            if (el.value == "cdExpectTime") {
              // el.defaultValue = [
              //   moment(params.dexpectStartTime * 1000),
              //   moment(params.dexpectStartTime * 1000),
              // ];
            } else if (el.value == item) {
              el.defaultValue = params[item];
            }
          });
          this.queryItems = queryItems;
          // 查询值的改变
          let queryParam = JSON.parse(JSON.stringify(this.queryParam));
          for (let index in queryParam) {
            if (index == item) {
              queryParam[index] = params[item];
            }
          }
          this.queryParam = queryParam;
        }
      } else {
        // this.queryItems = queryItems;
        // location.reload();
      }
    }
    this.GetCompaniesId();
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtnsJF").split(",");
  },
  methods: {
    // 获取分公司id
    GetCompaniesId() {
      GetCompanies()
        .then((res) => {
          // console.log(res);
          // this.filters.CompanyList.length = 0;

          res.data.forEach((el) => {
            this.CompanyListid.push(el.bc_id);
            this.CompanyList[el.bc_id] = el.bc_name;
          });
        })
        .then(() => {
          if (this.queryParam.dBcIds == "") {
            this.queryParam.dBcIds = this.CompanyListid.toString();
            this.$refs.table.refresh(true);
          }

          //当包含的分公司小于1个的时候，查询条件不显示分公司查询 表格不显示分公司名称
          if (this.CompanyListid.length < 1) {
            let data = [...this.queryItems];
            this.queryItems = data.filter((item) => item.label != "公司名称");
            if (this.queryItems.length == 0) {
              this.SSearchShow = false;
            }
            let data1 = [...this.columns];
            this.tableColumns = data1.filter(
              (item) => item.title != "公司名称"
            );
            this.columns = data1.filter((item) => item.title != "公司名称");
          }
        });
    },
    CompaniesResult(a) {
      return this.CompanyList[a];
    },
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(
        localStorage.getItem("UndispatchedOrderColumnsSet")
      );
      let value = ColumnsSet ? ColumnsSet : [];
      let selectColumns = columns.filter((el) => value.includes(el.dataIndex));
      this.tableColumns = selectColumns.length ? selectColumns : columns;
    },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      if (parameter) {
        this.queryParam.pageNo = parameter.pageNo;
        this.queryParam.pageSize = parameter.pageSize;
      } else {
      }
      if (this.queryParam.dBcIds != "") {
        let newObject = {};
        for (let el in this.queryParam) {
          if (this.queryParam[el] != "" && this.queryParam[el]) {
            newObject[el] = this.queryParam[el];
          }
        }
        let orderParam = Object.keys(newObject);
        const param = Object.assign({}, parameter, this.queryParam);
        const requestParameters = this.$order(param, orderParam);
        const params = {
          ...requestParameters,
          verify: this.$verify(requestParameters, orderParam),
        };
        return GetDispatchWorkList(params).then((res) => {
          // res.data.pageNo = res.data.page;
          // res.data.totalCount = res.data.sum;
          // console.log("未派单列表", res.data.data);
          // return res.data;
          // let data = res;
          res.data.data.forEach((el) => {
            let dReceiveName = [];
            if (el.constructionWorkers.length > 0) {
              el.constructionWorkers.forEach((item) => {
                dReceiveName.push(item.tworker.wname);
              });
            }

            el.dReceiveName = dReceiveName.toString();
            el.dconstructionNumber = el.constructionDetails.length;
          });

          return res.data.data ? res.data : res;
        });
      }
    },

    fetchUsers(value) {
      // this.queryParam = value;
      console.log("value", value);
      for (let el in this.queryParam) {
        this.queryParam[el] = value[el];
      }
      // console.log("this.queryParam", this.queryParam, value);
      if (this.queryParam.dBcIds == "") {
        this.queryParam.dBcIds = this.CompanyListid.toString();
      }
      // this.queryParam.cdDispatchState = 1;
      this.$refs.table.refresh(true);
    },
    onClose() {
      this.visibleDrawer = false;
      this.$refs.table.refresh(true);
    },
    afterVisibleChange(val) {},
    // 指定
    showDrawer(showPage, record, name) {
      console.log(showPage, name, record);
      this.DrawerTitle = name;
      this.visibleDrawer = true;
      this.AssignWorkerShow = false;
      this.SendOrdersShow = false;
      this.UndispatchedorderViewShow = false;
      this[showPage] = true;
      this.record = JSON.stringify(record);
    },
    // 指定工人数据改变
    recordChangeFn() {
      // this.AssignWorkerObject = JSON.parse(this.$refs.AssignWorker.newRecord);
      this.AssignWorkerValue = this.$refs.AssignWorker.newRecord;
      // console.log("recordChangeFn", this.AssignWorkerValue);
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      console.log(selectedRowKeys, selectedRows);
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // 取消派单项
    CancelDispatch(record) {
      console.log("取消派单项", record);

      let queryParam = {
        dId: record ? record.did : this.selectedRowKeys.toString(),
      };
      console.log("queryParam", queryParam);

      let orderParam = ["dId"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      DeleteDispatchWork(params).then((res) => {
        if (res.code == 200) {
          this.$message.success("取消派单项成功");
          this.$refs.table.refresh(true);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" >
.editBtnonly {
  margin-right: 10px;
}
.table-button-flexonly {
  display: flex;
  justify-content: space-between;
}
.cursorPointeronly {
  cursor: pointer;
  width: 100%;
  background-color: #fff;
  color: black;
}
.overlayClassNameonly {
  .ant-tooltip-inner {
    background-color: #fff !important;

    color: #333;
  }
}
</style>