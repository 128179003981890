<template>
  <div>
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="派单距离">
        <a-select v-model="form.ddistanceRules" placeholder="派单距离">
          <a-select-option
            v-for="item in DistanceList"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="截止时间">
        <a-date-picker
          v-model="form.ddispatchEndTime"
          @change="onChange_datePicker"
          @ok="onOk_datePicker"
          style="width: 100%"
          format="YYYY-MM-DD"
          value-format="X"
        />
        <!-- :show-time="{ format: 'HH:mm' }" -->
      </a-form-model-item>
      <a-form-model-item label="派单规则">
        <a-select
          v-model="form.druleType"
          placeholder="please select your zone"
        >
          <a-select-option :value="1">星级工匠 </a-select-option>
          <a-select-option :value="2"> 综合评价 </a-select-option>
          <a-select-option :value="3"> 指定岗位 </a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- 综合评价 -->
      <div v-if="evaluateShow" class="evaluate">
        <a-form-model-item label="">
          <a-checkbox-group
            v-model="HeadAndTail"
            @change="onChange_HeadAndTail"
          >
            <a-checkbox value="1" name="含首"> 含首 </a-checkbox>
            <a-checkbox value="2" name="含尾"> 含尾 </a-checkbox>
          </a-checkbox-group>
        </a-form-model-item>
        <div class="inputTitle">输入评分范围</div>
        <a-input class="input" v-model="HeadScore" />到
        <a-input class="input" v-model="TailScore" />
      </div>
      <!-- 星级工匠 -->
      <div v-if="StarlevelShow" class="evaluate">
        <a-form-model-item label="">
          <a-checkbox
            :indeterminate="indeterminate"
            :checked="checkAll"
            @change="onCheckAllChange"
          >
            全选
          </a-checkbox>
          <!-- <a-checkbox-group v-model="form.type"> -->
          <a-checkbox-group v-model="checkedList" @change="onChange">
            <a-checkbox
              v-for="item in plainOptions"
              :key="item.value"
              :value="item.value"
              :name="item.name"
            >
              {{ item.name }}
            </a-checkbox>
          </a-checkbox-group>
        </a-form-model-item>
      </div>

      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="onSubmit"> 派单 </a-button>
        <a-button style="margin-left: 10px" @click="onClose"> 取消 </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import { UpdateDispatchWork } from "@/api/apiJF/order";
import moment from "moment";

const plainOptions = [
  { value: 1, name: "一星工匠" },
  { value: 2, name: "二星工匠" },
  { value: 3, name: "三星工匠" },
  { value: 4, name: "四星工匠" },
];
const plainOptionsPost = [
  { value: 402, name: "瓦工工人" },
  { value: 403, name: "木工工人" },
  { value: 404, name: "油工工人" },
  { value: 405, name: "辅助工人" },
  { value: 406, name: "水电工人" },
];
// const defaultCheckedList = ["Apple", "Orange"];
const defaultCheckedList = [];
export default {
  props: {
    record: {
      type: String,
      default: "",
    },
    AssignWorkerValue: {
      type: String,
      default: "",
    },
  },
  name: "SendOrders",
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        ddistanceRules: "", //距离限制
        ddispatchRules: "", //抢单规则
        ddispatchEndTime: "", //派单截止日期
        druleType: "", //规则类型
        // cdWId: "", //工人id
        // cdWtId: "", //岗位id
        // cdPId: "", //施工项id
        // cdWsId: "", //工地id
        // cdWpId: "", //施工工序id
      },
      evaluateShow: false, //展示综合评价
      StarlevelShow: false, //展示星级工匠
      checkedList: defaultCheckedList, //选中的星匠
      indeterminate: true,
      checkAll: false,
      plainOptions,
      DistanceList: [
        { value: 5, label: "5km" },
        { value: 10, label: "10km" },
        { value: 20, label: "20km" },
        { value: 30, label: "30km" },
        { value: 40, label: "40km" },
        { value: "-1", label: "其他" },
      ],
      HeadAndTail: [],
      HeadScore: 0,
      TailScore: 5,
      HeadValue: 0,
      TailValue: 5,
    };
  },
  created() {
    let record = JSON.parse(this.record);
    console.log("created--record", record);
    for (let el in record) {
      this.form[el] = record[el];
    }
    console.log("this.AssignWorkerValue---created", this.AssignWorkerValue);
    this.form.ddispatchEndTime = this.form.ddispatchEndTime + "";
  },
  watch: {
    "form.druleType": {
      handler(newValue) {
        // console.log("form.druleType", newValue);
        if (newValue == 1) {
          // 星级工匠
          this.StarlevelShow = true;
          this.evaluateShow = false;
          this.plainOptions = plainOptions;
        } else if (newValue == 2) {
          // 综合评价
          this.evaluateShow = true;
          this.StarlevelShow = false;
        } else if (newValue == 3) {
          // 岗位
          this.StarlevelShow = true;
          this.evaluateShow = false;
          this.plainOptions = plainOptionsPost;
        }
        this.checkedList = [];
        this.checkAll = false;
      },
    },
    HeadAndTail: {
      handler(newValue) {
        console.log("是否含首尾", newValue);
        this.HeadValue = this.HeadScore;
        this.TailValue = this.TailScore;
        if (newValue.indexOf("1") > -1) {
          this.HeadValue = this.HeadScore - 1;
        }
        if (newValue.indexOf("2") > -1) {
          this.TailValue = this.TailScore * 1 + 1;
        }
        console.log("评分", this.HeadValue, this.TailValue);
      },
    },
    HeadScore: {
      handler(newValue) {
        if (this.HeadAndTail.indexOf("1") > -1) {
          this.HeadValue = this.HeadScore - 1;
        } else {
          this.HeadValue = newValue;
        }
        console.log("评分", this.HeadValue, this.TailValue);
      },
    },
    TailScore: {
      handler(newValue) {
        if (this.HeadAndTail.indexOf("2") > -1) {
          this.TailValue = this.TailScore - 1;
        } else {
          this.TailValue = newValue;
        }
        console.log("评分", this.HeadValue, this.TailValue);
      },
    },
  },
  methods: {
    onSubmit() {
      if (this.form.druleType == 1 || this.form.druleType == 4) {
        this.form.ddispatchRules = this.checkedList.toString();
      } else if (this.form.druleType == 2) {
        this.form.ddispatchRules = this.HeadValue + "," + this.TailValue;
      }
      console.log("submit!", this.form);
      this.AssignWorkerSendOrder();
    },
    onClose() {
      this.$emit("onClose");
    },
    onChange(checkedList) {
      //   console.log("checkedList", checkedList);
      this.indeterminate =
        !!checkedList.length && checkedList.length < this.plainOptions.length;
      this.checkAll = checkedList.length === this.plainOptions.length;
    },
    onCheckAllChange(e) {
      let valueAll = [];
      this.plainOptions.forEach((el) => {
        valueAll.push(el.value);
      });
      Object.assign(this, {
        checkedList: e.target.checked ? valueAll : [],
        indeterminate: false,
        checkAll: e.target.checked,
      });
      //   console.log("checkedList", this.checkedList);
    },
    onChange_datePicker(value, dateString) {
      console.log("Selected Time: ", value);
      // value.format("X")
      console.log("Formatted Selected Time: ", dateString);
    },
    onOk_datePicker(value) {
      console.log("onOk: ", value);
    },
    // 选择是否含首尾
    onChange_HeadAndTail(e) {},
    // 派单
    AssignWorkerSendOrder() {
      // this.form.constructionWorkers = this.AssignWorkerValue
      //   ? JSON.parse(this.AssignWorkerValue)
      //   : [];
      console.log("this.form.ddispatchEndTime", this.form.ddispatchEndTime);

      let params = this.form;
      params.ddispatchState = 2;
      // params.ddispatchEndTime = this.form.ddispatchEndTime.format("X");
      UpdateDispatchWork(params).then((res) => {
        console.log("派单", res.code);
        if (res.code == 200) {
          this.$message.success("派单成功");
          this.onClose();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.components-input-demo-size .ant-input {
  // width: 200px;
  margin: 0 8px 8px 0;
}
.evaluate {
  //   display: flex;
  //   justify-content: center;

  //   width: 720px;
  width: 58%;
  margin-left: 17%;
  border: 1px solid rgba(177, 177, 177, 0.5);
  padding: 20px;
  height: 200px;
  .inputTitle {
    margin: 20px 0;
  }
  .input {
    width: 80px;
  }
}
</style>
