<template>
  <div>
    <a-descriptions title="基本信息">
      <a-descriptions-item label="派单项"> Zhou Maomao </a-descriptions-item>
      <a-descriptions-item label="指定工人情况">
        1810000000
      </a-descriptions-item>
      <a-descriptions-item label="工人岗位">
        Hangzhou, Zhejiang
      </a-descriptions-item>
    </a-descriptions>
  </div>
</template>
<script>
import { STable, SSearch, ColumnsSet } from "@/components";
import { GetCompanies } from "@/api/device";

export default {
  name: "OrderSent",
  components: {
    STable,
    SSearch,
    ColumnsSet,
  },
  data() {
    this.queryItems = queryItems;
    // this.columns = columns;
    return {
      tableColumns: [],
      SSearchShow: true,
      // 查询参数
      queryParam: {
        cdWsWIdJl: "",
        cdBcIds: 18,
        cdWsName: "",
        cdExpectEndTime: "",
        cdExpectStartTime: "",
        cwPId: "",
        cdDispatchState: "2,3,4,5,6",
        // cwWsId: "",
        // cwPId: "",
        // cwWpId: "",
        // cwWId: "",
        // cwState: "1",

        pageNo: 1,
        pageSize: 10,
      },
      isShowBtns: [],
      record: {
        // cdBcIds: 0,
      },
      CompanyList: {},
      type: "",
      filters: {},
      CompanyListid: [],
      pageNo: 1,
      pageSize: 10,
      columns: [
        {
          title: "施工项",
          width: "150px",
          dataIndex: "cdPId",
          scopedSlots: { customRender: "cdPId" },
          // align: "center",
        },
        {
          title: "工地",
          // width: "100px",
          scopedSlots: { customRender: "tworksite" },
          dataIndex: "tworksite",
          ellipsis: true,
          // align: "center",
        },
        {
          title: "所需时间",
          width: "100px",
          scopedSlots: { customRender: "cdDuration" },
          dataIndex: "cdDuration",
          align: "center",
        },
        {
          title: "施工周期",
          width: "200px",
          scopedSlots: { customRender: "cwStartTime" },
          dataIndex: "cwStartTime",
          align: "center",
        },
      ],
    };
  },
  created() {
    this.GetCompaniesId();
    this.tableColumns = this.columns;
    this.changeColumns();
    this.filters = SelectOptions;
    // console.log(CompaniesResult(24));
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtnsJF").split(",");
  },
  methods: {
    // 获取分公司id
    GetCompaniesId() {
      GetCompanies()
        .then((res) => {
          // console.log(res);
          // this.filters.CompanyList.length = 0;

          res.data.forEach((el) => {
            this.CompanyListid.push(el.bc_id);
            this.CompanyList[el.bc_id] = el.bc_name;
          });
        })
        .then(() => {
          this.queryParam.cdBcIds = this.CompanyListid.toString();

          this.$refs.table.refresh(true);

          //当包含的分公司小于1个的时候，查询条件不显示分公司查询 表格不显示分公司名称
          if (this.CompanyListid.length < 1) {
            let data = [...this.queryItems];
            this.queryItems = data.filter((item) => item.label != "公司名称");
            if (this.queryItems.length == 0) {
              this.SSearchShow = false;
            }
            let data1 = [...this.columns];
            this.tableColumns = data1.filter(
              (item) => item.title != "公司名称"
            );
            this.columns = data1.filter((item) => item.title != "公司名称");
          }
        });
    },
    CompaniesResult(a) {
      return this.CompanyList[a];
    },
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(localStorage.getItem("OrderSentColumnsSet"));
      let value = ColumnsSet ? ColumnsSet : [];
      let selectColumns = this.columns.filter((el) =>
        value.includes(el.dataIndex)
      );
      this.tableColumns = selectColumns.length ? selectColumns : this.columns;
    },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      if (parameter) {
        this.queryParam.pageNo = parameter.pageNo;
        this.queryParam.pageSize = parameter.pageSize;
      } else {
      }
      let newObject = {};
      for (let el in this.queryParam) {
        if (this.queryParam[el] != "" && this.queryParam[el] != undefined) {
          newObject[el] = this.queryParam[el];
        }
      }
      let orderParam = Object.keys(newObject);
      const param = Object.assign({}, parameter, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetConstructionDetailList(params).then((res) => {
        // res.data.pageNo = res.data.page;
        // res.data.totalCount = res.data.sum;
        // console.log("排期列表", res);
        return res.data.data ? res.data : res;
      });
    },

    fetchUsers(value) {
      // this.queryParam = value;
      for (let el in this.queryParam) {
        this.queryParam[el] = value[el];
      }
      // console.log("this.queryParam", this.queryParam, value);
      if (this.queryParam.cdBcIds == "") {
        this.queryParam.cdBcIds = this.CompanyListid.toString();
      }
      this.queryParam.cdDispatchState = "2,3,4,5,6";
      this.$refs.table.refresh(true);
    },
  },
};
</script>
<style lang="less" scoped>
</style>
