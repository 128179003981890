<template>
  <div class="AssignWorker">
    <div v-if="TreeTtransferShow" class="transfer_out">
      <a-transfer
        class="tree-transfer"
        :titles="['未选', '已选']"
        :data-source="dataSource"
        :target-keys="targetKeys"
        :render="(item) => item.title"
        :show-select-all="false"
        :operations="['添加', '移除']"
        @change="onChange"
        :list-style="{
          width: '250px',
          height: '500px',
        }"
      >
        <!-- 
         @search="handleSearch"
        show-search
        :filter-option="filterOption"-->
        <template
          slot="children"
          slot-scope="{
            props: { direction, selectedKeys },
            on: { itemSelect },
          }"
        >
          <a-tree
            v-if="direction === 'left'"
            blockNode
            class="treeClass"
            checkable
            checkStrictly
            defaultExpandAll
            :checkedKeys="[...selectedKeys, ...targetKeys]"
            :treeData="treeData"
            @check="
              (_, props) => {
                onChecked(
                  _,
                  props,
                  [...selectedKeys, ...targetKeys],
                  itemSelect
                );
              }
            "
            @select="
              (_, props) => {
                onChecked(
                  _,
                  props,
                  [...selectedKeys, ...targetKeys],
                  itemSelect
                );
              }
            "
          />
        </template>
      </a-transfer>
    </div>
    <div class="Btns">
      <a-button type="primary" @click="saveBtn" class="closeBtn">保存</a-button>
      <a-button @click="onClose">取消</a-button>
    </div>
  </div>
</template>
<script>
import { UpdateDispatchWork } from "@/api/apiJF/order";
import { GetWorkerTreeList } from "@/api/apiJF/order";
// const treeData = [
//   { key: "0-0", title: "0-0", children: [] },
//   {
//     key: "0-1",
//     title: "0-1",
//     children: [
//       { key: "0-1-0", title: "0-1-0" },
//       { key: "0-1-1", title: "0-1-1" },
//     ],
//   },
//   { key: "0-2", title: "0-3", children: [] },
// ];

// const transferDataSource = [];
// function flatten(list = []) {
//   list.forEach((item) => {
//     transferDataSource.push(item);
//     flatten(item.children);
//   });
// }
// flatten(JSON.parse(JSON.stringify(treeData)));

function isChecked(selectedKeys, eventKey) {
  return selectedKeys.indexOf(eventKey) !== -1;
}

function handleTreeData(data, targetKeys = []) {
  data.forEach((item) => {
    // item["disabled"] = targetKeys.includes(item.key);
    if (item.children) {
      handleTreeData(item.children, targetKeys);
      //   item.disabled = true;
      item.selectable = false; //是否可选
      item.checkable = false; //设置独立节点是否展示 Checkbox
    }
  });
  return data;
}

export default {
  name: "AssignWorker",
  props: {
    record: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      targetKeys: [],
      transferDataSource: [],
      dataSource: [],
      queryParam: {
        wBcIds: "",
      },
      treeData: [],
      workerList: [],
      TreeTtransferShow: false,
      newRecord: "",
    };
  },
  created() {
    this.targetKeys = [];
    let record = JSON.parse(this.record);
    console.log("created--record", record);
    // cwWId
    //  this.targetKeys
    let targetKeys = [];
    record.constructionWorkers.forEach((el) => {
      targetKeys.push(el.cwWId + "");
    });
    this.targetKeys = targetKeys;
    // for (let el in record) {
    //   this.form[el] = record[el];
    // }
    this.queryParam.wBcIds = record.dbcId;
    this.GetWorkerList();
  },
  computed: {
    // treeData() {
    //   return handleTreeData(treeData, this.targetKeys);
    // },
  },
  watch: {
    treeData() {
      return handleTreeData(this.treeData, this.targetKeys);
    },
  },
  methods: {
    // 查询工人
    GetWorkerList() {
      let newObject = {};
      for (let el in this.queryParam) {
        if (this.queryParam[el] != "" && this.queryParam[el]) {
          newObject[el] = this.queryParam[el];
        }
      }
      let orderParam = Object.keys(newObject);
      const param = Object.assign({}, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetWorkerTreeList(params)
        .then((res) => {
          // res.data.pageNo = res.data.page;
          // res.data.totalCount = res.data.sum;
          //   console.log("工人列表", res.data);
          //   this.workerList = res.data;
          let newData = res.data;
          newData.forEach((el) => {
            el.children.forEach((item) => {
              item.key = item.key + "";
            });
          });
          //   this.treeData = res.data;
          this.treeData = newData;
          this.treeData = handleTreeData(this.treeData, this.targetKeys);

          this.transferDataSource = [];
          this.flatten(JSON.parse(JSON.stringify(this.treeData)));
          this.dataSource = this.transferDataSource;
        })
        .then(() => {
          this.workerList = JSON.stringify(this.treeData);
          this.TreeTtransferShow = true;
        });
    },
    // 计算数组长度
    flatten(list = []) {
      list.forEach((item) => {
        // this.transferDataSource.push(item);
        // flatten(item.children);
        item.children.forEach((el) => {
          el.key = el.key + "";
          this.transferDataSource.push(el);
          // flatten(item.children);
        });
      });
    },
    onChange(targetKeys) {
      console.log("Target Keys:", targetKeys);
      this.targetKeys = targetKeys;
    },
    onChecked(_, e, checkedKeys, itemSelect) {
      console.log("onChecked", checkedKeys);
      const { eventKey } = e.node;
      itemSelect(eventKey, !isChecked(checkedKeys, eventKey));
    },
    saveBtn() {
      console.log("保存", this.targetKeys);
      // this.$message.success("保存成功");
      this.getNewWorkerList();
    },
    // 根据选中工人id构造数组
    getNewWorkerList() {
      let record = JSON.parse(this.record);
      let newWorkerList = [];
      this.targetKeys.forEach((el) => {
        let object = {};
        let cwWtId = this.getParanId(el);
        object.cwBcId = record.dbcId;
        object.cwDId = record.did; //派单id
        // object.cwWsId = record.cdWsId;
        // object.cwWpId = record.cdWpId;
        // object.cwPId = record.cdPId;
        object.cwWtId = cwWtId; //岗位id
        object.cwWId = el; //工人id
        // object.cwPrice = record.tproject.pworkPrice;
        object.cwStartTime = record.dexpectStartTime;
        object.cwEndTime = record.dexpectEndTime;
        object.cwDuration = record.dduration; //施工时长
        object.cwDispatchState = "1";
        // object.cwQuantity = record.cdQuantity;
        // object.cwUnit = record.cdUnit;
        newWorkerList.push(object);
        // record.constructionWorkers = newWorkerList;
      });
      //   console.log("newWorkerList", newWorkerList);
      this.newRecord = JSON.stringify(newWorkerList);
      // this.$emit("recordChangeFn", this.newRecord);
      this.AssignWorkerSendOrder();
    },
    onClose() {
      this.$emit("onClose");
    },
    handleSearch(dir, value) {
      //   console.log("search:", dir, value);
      //   let workerList = JSON.parse(this.workerList);
      //   if (value != "") {
      //     let newList = [];
      //     workerList.forEach((el) => {
      //       newList.push({
      //         checkable: false,
      //         disabled: false,
      //         key: el.key,
      //         selectable: false,
      //         title: el.title,
      //         children: [],
      //       });
      //       //   }
      //       el.children.forEach((item) => {
      //         if (item.title.indexOf(value) > -1) {
      //           newList.forEach((newKey) => {
      //             if (newKey.key == el.key) {
      //               newKey.children.push(item);
      //             }
      //           });
      //         }
      //       });
      //       //   console.log("newChildList", newChildList, parentKey);
      //     });
      //     console.log("newList", newList);
      //     this.treeData = newList;
      //   } else {
      //     console.log("value==");
      //     this.treeData = JSON.parse(this.workerList);
      //   }
    },
    filterOption(inputValue, option) {
      return option.title.indexOf(inputValue) > -1;
    },
    // 寻找父级id
    getParanId(id) {
      let workerList = JSON.parse(this.workerList);
      for (let el in workerList) {
        for (let item in workerList[el].children) {
          let child = workerList[el].children[item];
          if (child.key == id) {
            return workerList[el].key;
            break;
          }
        }
      }
    },
    // 派单
    AssignWorkerSendOrder() {
      let params = JSON.parse(this.record);
      params.constructionWorkers = JSON.parse(this.newRecord);
      console.log(params);
      UpdateDispatchWork(params).then((res) => {
        // console.log("派单", res.code);
        if (res.code == 200) {
          this.$message.success("指定工人成功");
          this.onClose();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style scoped  lang="less">
.AssignWorker {
  background-color: #fff;
  height: 100%;
  overflow: hidden;
}
.tree-transfer .ant-transfer-list:first-child {
  width: 50%;
  flex: none;
  position: relative;
}
.Btns {
  width: 100%;
  margin: 40px 0;
  //   background-color: pink;
  display: flex;
  justify-content: center;
  .closeBtn {
    margin-right: 20px;
  }
}

.treeClass {
  position: absolute;
  //   height: 700px;
  height: 90%;
  width: 95%;
  overflow: auto;
}
</style>
