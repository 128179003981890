var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"AssignWorker"},[(_vm.TreeTtransferShow)?_c('div',{staticClass:"transfer_out"},[_c('a-transfer',{staticClass:"tree-transfer",attrs:{"titles":['未选', '已选'],"data-source":_vm.dataSource,"target-keys":_vm.targetKeys,"render":(item) => item.title,"show-select-all":false,"operations":['添加', '移除'],"list-style":{
        width: '250px',
        height: '500px',
      }},on:{"change":_vm.onChange},scopedSlots:_vm._u([{key:"children",fn:function({
          props: { direction, selectedKeys },
          on: { itemSelect },
        }){return [(direction === 'left')?_c('a-tree',{staticClass:"treeClass",attrs:{"blockNode":"","checkable":"","checkStrictly":"","defaultExpandAll":"","checkedKeys":[...selectedKeys, ..._vm.targetKeys],"treeData":_vm.treeData},on:{"check":(_, props) => {
              _vm.onChecked(
                _,
                props,
                [...selectedKeys, ..._vm.targetKeys],
                itemSelect
              );
            },"select":(_, props) => {
              _vm.onChecked(
                _,
                props,
                [...selectedKeys, ..._vm.targetKeys],
                itemSelect
              );
            }}}):_vm._e()]}}],null,false,3428750106)})],1):_vm._e(),_c('div',{staticClass:"Btns"},[_c('a-button',{staticClass:"closeBtn",attrs:{"type":"primary"},on:{"click":_vm.saveBtn}},[_vm._v("保存")]),_c('a-button',{on:{"click":_vm.onClose}},[_vm._v("取消")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }